<template>
  <view-item title="回访历史">
    <template #manifest>
      <table-manifest
        :ajax="{ action: 'GET /enocloud/service/return/visit/simple' }"
        :props="{ start: 'preparedDateStart', end: 'preparedDateEnd' }"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
      >
        <en-table-column label="单据编号" prop="serviceSerialNo"></en-table-column>
        <en-table-column label="车牌号" prop="plateNo"></en-table-column>
        <template #form="{ data }">
          <en-form-item label="结算日期">
            <en-date-picker v-model:start="data.settlementStartDate" v-model:end="data.settlementEndDate" class="w-full"></en-date-picker>
          </en-form-item>
          <en-form-item label="维修类别">
            <select-maintain
              v-model="data.serviceCategoryId"
              :ajax="{ action: 'GET /enocloud/common/service/category' }"
              :props="{ label: 'name', value: 'id' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="回访专员">
            <select-maintain
              v-model="data.preparedById"
              :ajax="{
                action: 'GET /enocloud/common/user',
                params: (params, name) => (params.payload = { name, includingDestroyed: true, advisorFirst: true })
              }"
              :props="{ label: 'name', value: 'id' }"
              multiple
              class="w-full"
            ></select-maintain>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.service?.id">
      <en-card body-class="flex gap-6 text-sm">
        <span>{{ form.data.service?.vehicle?.plateNo }}</span>
        <span>{{ form.data.service?.sender }}/{{ form.data.service?.senderTelephone }}</span>
        <span>单号：{{ form.data.service?.serialNo }}</span>
        <span>{{ form.data.service?.vehicle?.vehicleSpec.join('/') }}</span>
        <span>{{ form.data.service?.vehicle?.vin }}</span>
      </en-card>

      <en-card class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane label="维修结算清单" name="settlement">
                <en-scrollbar :height="height - 55">
                  <div v-if="form.settlement.empty" class="flex justify-center p-10">暂无维修结算清单</div>
                  <print-template v-else :fields="form.settlement.data" :loading="form.settlement.loading"></print-template>
                </en-scrollbar>
              </en-tab-pane>

              <en-tab-pane v-if="form.data.service?.fakeService?.id" label="附表结算单" name="fakeSettlement">
                <div v-if="form.fakeSettlement.empty" class="flex justify-center p-10">暂无维修结算清单</div>
                <print-template v-else :fields="form.fakeSettlement.data" :loading="form.fakeSettlement.loading"></print-template>
              </en-tab-pane>

              <en-tab-pane label="历史维修项目" name="maintenance-history">
                <table-dynamic
                  code="SRDMTHF"
                  :height="height - 55"
                  :data="maintenance.data"
                  :method="maintenance.get"
                  :paging="maintenance.paging"
                  :loading="maintenance.loading"
                  pagination
                ></table-dynamic>
              </en-tab-pane>

              <en-tab-pane label="历史维修配件" name="material-history">
                <table-dynamic
                  code="SRDMRHF"
                  :height="height - 55"
                  :data="material.data"
                  :method="material.get"
                  :paging="material.paging"
                  :loading="material.loading"
                  pagination
                ></table-dynamic>
              </en-tab-pane>

              <en-tab-pane label="评价信息" name="insurance">
                <en-table :data="form.data.service?.serviceEstimates" :loading="form.loading" :height="height - 55">
                  <en-table-column label="整体评价">
                    <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceEstimateDto'] }">
                      <en-rate :model-value="row.advisorLevel" disabled></en-rate>
                    </template>
                  </en-table-column>
                  <en-table-column label="评价时间" prop="preparedDatetime">
                    <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceEstimateDto'] }">
                      {{ formatDate(row.preparedDatetime, true) }}
                    </template>
                  </en-table-column>
                  <en-table-column label="评价内容" prop="comment"></en-table-column>
                </en-table>
              </en-tab-pane>

              <en-tab-pane v-if="store.accessRightsHash.SERVICE_RETURN_VISIT_HISTORY_QUERY" label="回访记录" name="record">
                <en-table :data="record.data" :loading="record.loading" :height="height - 55" pagination :paging="record.paging">
                  <en-table-column label="回访时间" prop="preparedDatetime">
                    <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto'] }">
                      {{ formatDate(row.preparedDatetime, true) }}
                    </template>
                  </en-table-column>
                  <en-table-column label="回访对象" prop="intervieweeType.message"></en-table-column>
                  <en-table-column label="回访专员" prop="preparedBy.name"></en-table-column>
                  <en-table-column label="回访方式" prop="followUpType.message"></en-table-column>
                  <en-table-column label="回访状态">
                    <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto'] }">
                      {{ row.status?.value ? '成功' : '失败' }}
                    </template>
                  </en-table-column>
                  <en-table-column label="总体评分">
                    <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto'] }">
                      <en-rate :model-value="row.level" disabled></en-rate>
                    </template>
                  </en-table-column>
                  <en-table-column label="回访备注" prop="comment"></en-table-column>
                </en-table>
              </en-tab-pane>

              <en-tab-pane label="投诉处理" name="complaint">
                <en-table :data="complaint.data" :loading="complaint.loading" :height="height - 55">
                  <en-table-column label="投诉编号" prop="serialNo" width="100"></en-table-column>
                  <en-table-column label="投诉状态" prop="status.message" width="100"></en-table-column>
                  <en-table-column label="工单号" prop="service.serialNo"></en-table-column>
                  <en-table-column label="服务顾问" prop="service.advisor.name" width="100"></en-table-column>
                  <en-table-column label="回访人员" prop="visitor.name" width="100"></en-table-column>
                  <en-table-column label="车牌号" prop="service.vehicle.plateNo" width="100"></en-table-column>
                  <en-table-column label="车主" prop="service.vehicle.owner.name" width="100"></en-table-column>
                  <en-table-column label="联系电话" prop="service.vehicle.owner.cellphone" width="100"></en-table-column>
                  <en-table-column label="进厂日期" prop="service.enterDatetime" width="100"></en-table-column>
                  <en-table-column label="投诉日期" prop="complaintDate" width="100">
                    <template #default="{ row }: { row: EnocloudServiceDefinitions['ComplaintDto'] }">
                      {{ formatDate(row.complaintDate) }}
                    </template>
                  </en-table-column>
                  <en-table-column label="投诉来源" prop="way" width="100"> </en-table-column>
                  <en-table-column label="投诉对象" width="100">
                    <template #default="{ row }: { row: EnocloudServiceDefinitions['ComplaintDto'] }">
                      {{ (row.targets as Array<{ message: string }>).map((item) => item.message).join(',') }}
                    </template>
                  </en-table-column>
                  <en-table-column label="有效投诉" prop="valid.message" width="100"></en-table-column>
                  <en-table-column label="责任人" prop="liablePersons">
                    <template #default="{ row }: { row: EnocloudServiceDefinitions['ComplaintDto'] }">
                      {{ row.liablePersons.map((item) => item.name).join(',') }}
                    </template>
                  </en-table-column>
                  <en-table-column label="服务经理" prop="serviceManager.name" width="100"></en-table-column>
                  <en-table-column label="预约进厂检查日期" prop="timeToInspect" width="140">
                    <template #default="{ row }: { row: EnocloudServiceDefinitions['ComplaintDto'] }">
                      {{ formatDate(row.timeToInspect) }}
                    </template>
                  </en-table-column>
                  <en-table-column label="客户再次回访结果" prop="revisitCSI.message" width="140"></en-table-column>
                </en-table>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <visit-history-dialog v-model="dialog.visible"></visit-history-dialog>
</template>

<script lang="ts">
import VisitHistoryDialog from '@client/components/visit-history-dialog.vue'
import { PrintTemplate } from '@enocloud/business'

const formDataInit = (): EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto'] => {
  return {} as any
}

export default factory({
  components: { VisitHistoryDialog, PrintTemplate },

  config: {
    children: {
      manifest: {
        row: {
          async click(row: EnocloudServiceDefinitions['ServiceReturnVisitQueryDto']) {
            const res = await this.ajax('GET /enocloud/service/:serviceId', { paths: [row.serviceId] })
            this.form.data.service = res.data[0]
            this.maintenance.get()
            this.material.get()
            this.record.get()
            this.complaint.get()

            const serviceTemplateRes = await this.ajax('GET /enocloud/common/report/template', { payload: { type: 'SVC' } })
            const serviceSettlementTemplate = serviceTemplateRes.data.find((item: any) => item.name === '工单结算单')
            if (serviceSettlementTemplate) {
              this.form.settlement.empty = false
              this.form.settlement.get({ paths: [this.form.data.service?.id, serviceSettlementTemplate.id] })
            }

            if (this.form.data.service?.fakeService?.id) {
              const fakeServiceTemplateRes = await this.ajax('GET /enocloud/common/report/template', { payload: { type: 'SVCF' } })
              const fakeServiceSettlementTemplate = fakeServiceTemplateRes.data.find((item: any) => item.name.includes('工单附表结算单'))
              if (fakeServiceSettlementTemplate) {
                this.form.fakeSettlement.empty = false
                this.form.fakeSettlement.get({ paths: [this.form.data.service.fakeService?.id, fakeServiceSettlementTemplate.id] })
              }
            }
          }
        }
      },
      tabs: {
        active: 'settlement'
      },
      form: {
        data: formDataInit(),
        children: {
          settlement: {
            empty: true,
            ajax: {
              get: {
                action: 'GET /enocloud/service/:serviceId/template/:templateId/print',
                data: 'array',
                loading: true
              }
            }
          },
          fakeSettlement: {
            empty: true,
            ajax: {
              get: {
                action: 'GET /enocloud/service/:serviceId/template/:templateId/print',
                data: 'array',
                loading: true
              }
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      maintenance: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/:vehicleId/maintenance/history',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.paths = [this.form.data.service?.vehicle?.id]
            }
          }
        }
      },
      material: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/:vehicleId/material/history',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.paths = [this.form.data.service?.vehicle?.id]
            }
          }
        }
      },
      record: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/return/visit/history',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { serviceId: this.form.data.service?.vehicle?.id }
            }
          }
        }
      },
      complaint: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/complaint',
            data: 'array',
            loading: true,
            params(params) {
              params.payload = { serviceSerialNo: this.form.data.service?.serialNo }
            }
          }
        }
      }
    }
  }
})
</script>
